import axios from 'axios'

const authBaseUrl = import.meta.env.VITE_AUTH_BASE_URL.endsWith('/')
    ? import.meta.env.VITE_AUTH_BASE_URL
    : `${import.meta.env.VITE_AUTH_BASE_URL}/`;

console.log(`Auth service URL: ${authBaseUrl}`)

interface LoginRequest {
  clientId: string;
  clientSecret: string;
}

interface LoginResponse {
  token: string;
}

export async function loginAsync(clientId: string, clientSecret: string): Promise<string | undefined> {

    const data = {
      clientId: clientId,
      clientSecret: clientSecret,
    } as LoginRequest;

    const url = new URL("/v1/login", authBaseUrl).href;
    console.log('Sending message to url: ', url);
    try {
        const response = await axios.post<LoginResponse>("/v1/login", data);
        return response.data.token;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                console.log(error.response.data);
            } else if (error.request) {
                console.log(error.request);
            }
        } else {
            console.log("Auth error:", error instanceof Error ? error.message : String(error));
        }
        return undefined;
    }
}
