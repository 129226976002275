/* eslint-disable react/react-in-jsx-scope */
import { Settings } from "luxon";
import { Navigate, Route, Routes, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { ChatPage } from "./pages/chat/ChatPage";
import Login from "./pages/login/Login";
import { useAuth } from "./states/AuthContext";
import Signin from "./pages/login/Signin";
import { ModeType, persistModeType } from "./utils/localStorageUtils";
import LoginSmfAuth from "./pages/loginauth/LoginSmfAuth";

const useSmfAuth = import.meta.env.VITE_USE_SMF_AUTH === "true";

export default function App() {
  Settings.defaultLocale = "en-UK";

  createBrowserRouter([
    {
      path: "/",
      element: <ChatPage />,
    },
    {
      path: "/chat",
      element: <ChatPage />,
    },
  ]);

  const { isAuthenticated } = useAuth();
  const isMastercam = new URLSearchParams(window.location.search).has(
    "mastercam"
  );

  if (isMastercam || useSmfAuth) {
    persistModeType(ModeType.MastercamMode);
  }

  return (
    <div className="body">
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <ChatPage />
            ) : useSmfAuth ? (
              <LoginSmfAuth />
            ) : (
              <Login />
            )
          }
        />
        <Route path="/authentication/callback" element={<Signin />} />
        <Route
          path="/authentication/signout"
          element={useSmfAuth ? <LoginSmfAuth /> : <Login />}
        ></Route>
        <Route
          path="/chat"
          element={isAuthenticated ? <ChatPage /> : <Navigate to="/" />}
        />
      </Routes>
    </div>
  );
}
