/* eslint-disable react/react-in-jsx-scope */
import { Button, Image, Tooltip } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import {
  getChatType,
  persistChatType,
  ChatModes,
  isMastercamMode
} from "../../../../utils/localStorageUtils";

import "./ChatSettingsBar.css";

import iconHelp from "../../../../assets/Help Icon 16.png";
import iconCommand from "../../../../assets/Command icon 16.png";

export function ChatSettingsBar() {
  
  const [selectedButton, setSelectedButton] = useState<ChatModes>(ChatModes.Analysis);

  useEffect(() => {
    const storedChatType = getChatType() ?? ChatModes.Analysis;
    setSelectedButton(storedChatType);
  }, []);

  const handleButtonClick = (chatMode: ChatModes) => {
    console.log("Mode : " + chatMode);
    setSelectedButton(chatMode);
    persistChatType(chatMode);
  };

  return (
    <>
    {isMastercamMode() && <div className="InputSettings">
      <Tooltip content="Search using Help and Commands" relationship="label">
        <Button
          className="InputSettingsButton"
          onClick={() => handleButtonClick(ChatModes.Analysis)}
          style={{ backgroundColor: selectedButton === ChatModes.Analysis ? '#E5ECF5' : 'white' }}
          appearance="outline"
          shape="rounded">
          Help and Command
        </Button>
      </Tooltip>
      <Tooltip content="Search using Help only" relationship="label">
        <Button
          className="InputSettingsButton"
          onClick={() => handleButtonClick(ChatModes.Help)}
          style={{ backgroundColor: selectedButton === ChatModes.Help ? '#E5ECF5' : 'white' }}
          appearance="outline"
          shape="rounded">
          <Image src={iconHelp} alt="Help" />
          Help
        </Button>
      </Tooltip>
      <Tooltip content="Search using Commands only" relationship="label">
        <Button
          className="InputSettingsButton"
          onClick={() => handleButtonClick(ChatModes.Command)}
          style={{ backgroundColor: selectedButton === ChatModes.Command ? '#E5ECF5' : 'white' }}
          shape="rounded"
          appearance="outline">
          <Image src={iconCommand} alt="Command" />
          Command
        </Button>
      </Tooltip>
    </div>
    }
    </>
  );
}
