/* eslint-disable react/react-in-jsx-scope */
import { HubConnection } from "@microsoft/signalr";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { Conversation } from "../../models/Conversation";
import { Message, SenderType, EventType } from "../../models/Message";
import {
  GetStreamingConnection,
  SendFeedbackAsync,
  getConfigurationAsync,
} from "../../services/ChatService";
import { AppFeatureContext, appInitialContext } from "../../states/AppContext";
import { useAuth } from "../../states/AuthContext";
import {
  ChatFeatureContext,
  ChatFeatureContextHandler,
  initialContext as chatInitialContext,
  initialContextHandler,
} from "../../states/ChatContext";
import { pageStyles } from "../PageStyles";
import { ChatHistoryList } from "./components/ChatHistoryList/ChatHistoryList";
import { ChatInputBox } from "./components/ChatInputBox/ChatInputBox";
import {
  ToBackendAdditionalData,
  ToBackendMessage,
} from "./components/ChatMessage/ToBackendMessage";
import { getChatType, isMastercamMode } from "../../utils/localStorageUtils";
import { MastercamMessage } from "../../models/MastercamMessage";

import { ChatToolBar } from "./components/ChatToolBar/ChatToolBar";
import "./ChatPage.css";

export function ChatPage() {
  const styles = pageStyles();
  const [appContext, setAppContext] = useState(appInitialContext);
  const [context, setContext] = useState(chatInitialContext);
  const [contextHandler, setContextHandler] = useState(initialContextHandler);
  const [
    streamingConnection,
    setStreamingConnection,
  ] = useState<HubConnection | null>(null);
  const { token, signOut } = useAuth();

  useEffect(() => {
    setContextHandler((h) => ({
      ...h,
      onSendMessage: onSendMessage,
      onSendFeedback: onSendFeedback,
      onRestartConversation: onRestartConversation,
    }));
  }, [context]);

  useEffect(() => {
    console.log("InitApplication");

    const getConfiguration = async () => {
      const configuration = await getConfigurationAsync();
      setAppContext((c) => ({ ...c, configuration: configuration }));
      initConversation();
    };

    getConfiguration();
  }, []);

  let started = false;

  const initConversation = async () => {
    console.log("initConversation");
    if (started) {
      console.log("already started");
      return;
    }
    started = true;
    const conversationId: string = uuid();
    console.log("conversation id", conversationId);
    const metadata = {};
    const connection = GetStreamingConnection(token ?? "");
    // Start the SignalR connection
    connection
      .start()
      .then(() => {
        console.log("Connection invoked for conversation", conversationId);
        connection.invoke("ConnectToAgent", conversationId);
      })
      .catch((err) => {
        console.error("SignalR connection error:", err);
        if (err.message.includes("401")) {
          signOut();
        }
      });

    connection.onclose(async (error) => {
      console.log("Connection closed:", error);
      signOut();
    });

    connection.onreconnecting(async () => {
      console.log("Connection lost, trying to reconnect...");
    });

    connection.onreconnected(() => {
      console.log("Reconnected to SignalR Hub");
    });

    console.log("SignalR connection established");
    setStreamingConnection(connection);

    // Register the ReceiveMessage event
    connection.on("ReceiveMessage", (message: Message) => {
      console.log("Additional params" + message.additionalData?.params);
      console.log("Received message " + JSON.stringify(message));
      if (message.sender === SenderType.FTFunction) {
        if (isMastercamMode() && window?.chrome?.webview) {
          const params = message.additionalData?.params ?? "";
          const mastercamMessage = new MastercamMessage(message.text, params);
          window.chrome.webview.postMessage(mastercamMessage);
        }
      }
      // switch (message.sender) {
      //   case SenderType.LoadingNotification:
      //     setContext((c) => ({
      //       ...c,
      //       isLoading: true,
      //       isTakingTooLong: false,
      //       loadingMessage: message.text,
      //       conversation: c.conversation,
      //     }));
      //     break;
      //   default:
      setContext((c) => ({
        ...c,
        //isLoading: message.sender === SenderType.LoadingNotification,
        isTakingTooLong: false,
        conversation: {
          ...c.conversation,
          messages: [...c.conversation.messages, message],
        },
      }));
      //     break;
      // }
    });

    setContext((c) => ({
      ...c,
      conversation: { id: conversationId, metadata, messages: [] },
    }));
  };

  const onSendMessage = useCallback(
    (messageText: string) => {
      const questionId = uuid().toString();

      console.log(
        "Send message [" +
          messageText +
          "] id [" +
          questionId +
          "] to conversation id [" +
          context.conversation.id +
          "]"
      );

      let additionalData: ToBackendAdditionalData | undefined = undefined;
      const chatType = getChatType();
      if (chatType) {
        additionalData = { chatType: chatType };
      }

      const sendMessage = async () => {
        const userMessage: ToBackendMessage = {
          id: questionId,
          correlationId: questionId,
          conversationId: context.conversation.id,
          sender: SenderType.User,
          event: EventType.UserInput,
          text: messageText,
          additionalData: additionalData,
          timestamp: new Date(),
          feedback: undefined,
          isError: false,
        };

        setContext((c) => ({
          ...c,
          //isLoading: true,
          conversation: {
            ...c.conversation,
            messages: [...c.conversation.messages, userMessage],
          },
        }));

        if (streamingConnection?.state != "Connected") {
          signOut();
        }
        streamingConnection?.send("ProcessMessage", userMessage);

        const waitTime = 20000;

        setTimeout(() => {
          setContext((c) => ({
            ...c,
            isTakingTooLong: true,
          }));
        }, waitTime);
      };
      sendMessage().catch((err) => {
        console.error("SignalR send error:", err);
        if (err.message.includes("401")) {
          signOut();
        }
      });
    },
    [context, streamingConnection]
  );

  function onSendFeedback(message: Message, feedback: -1 | 1) {
    console.log("onSendFeedback");

    const sendFeedbackAsync = async () =>
      await SendFeedbackAsync(
        context.conversation.id,
        message.id,
        feedback.toString(),
        message.text
      );

    sendFeedbackAsync();

    const conversation = context.conversation;
    const updatedConversation: Conversation = {
      ...conversation,
      messages: conversation.messages.map((m) =>
        m.id === message.id ? { ...m, feedback: feedback } : m
      ),
    };

    setContext((c) => ({ ...c, conversation: updatedConversation }));
  }

  const onRestartConversation = useCallback(async () => {
    if (streamingConnection) {
      try {
        const newConversationId = uuid();
        console.log(
          "Restart conversation with new id [" + newConversationId + "]"
        );

        await streamingConnection.invoke(
          "RestartConversation",
          newConversationId
        );

        setContext((c) => ({
          ...c,
          conversation: {
            id: newConversationId,
            metadata: c.conversation.metadata,
            messages: [],
          },
        }));
      } catch (error) {
        console.error("Error restarting conversation: ", error);
      }
    }
  }, [streamingConnection, context]);

  return (
    <>
      <AppFeatureContext.Provider value={appContext}>
        {appContext.configuration?.chatConfiguration ? (
          <ChatFeatureContext.Provider value={context}>
            <ChatFeatureContextHandler.Provider value={contextHandler}>
              <div className={styles.sectionContainer}>
                <ChatToolBar />
                <ChatHistoryList />
              </div>
              <footer>
                <div className="inbox-box">
                  <ChatInputBox />
                </div>
              </footer>
            </ChatFeatureContextHandler.Provider>
          </ChatFeatureContext.Provider>
        ) : (
          <></>
        )}
      </AppFeatureContext.Provider>
    </>
  );
}
