// Define the keys as constants for better maintainability
const LOCAL_STORAGE_KEYS = {
    CHAT_TYPE: 'chatType',
    USERNAME: 'username',
    TOKEN: 'token',
    MODE: 'mode',
    AVATAR: 'avatar'
};

export enum ChatModes {
    Analysis = "analysis",
    Command = "ft",
    Help = "docchat",
};

export enum ModeType {
    MastercamMode = "Mastercam",
    BrowserMode = "Browser"
  }

/**
 * Utility to set the mode in localStorage.
 * @param value - The mode type
 */
export const persistModeType = (value: ModeType): void => {
    sessionStorage.setItem(LOCAL_STORAGE_KEYS.MODE, value);
};

/**
 * Utility to get the mode from localStorage.
 * @returns The mode type, or null if not found.
 */
export const getModeType = (): ModeType | null => {
    return sessionStorage.getItem(LOCAL_STORAGE_KEYS.MODE) as ModeType;
};

/**
 * Check if Mastercam mode is active
 * @returns True if Mastercam mode is active
 */
export const isMastercamMode = () : boolean =>
{
    const currentMode = sessionStorage.getItem(LOCAL_STORAGE_KEYS.MODE) as ModeType;
    if (currentMode != null)
    {
        return currentMode == ModeType.MastercamMode;
    }
    return false;
}

/**
 * Utility to set the chatType in localStorage.
 * @param value - The chat type to be stored.
 */
export const persistChatType = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CHAT_TYPE, value);
};

/**
 * Utility to get the chatType from localStorage.
 * @returns The chat type, or null if not found.
 */
export const getChatType = (): ChatModes | null => {
    if (!isMastercamMode()) {
        return ChatModes.Help;
    }
    return localStorage.getItem(LOCAL_STORAGE_KEYS.CHAT_TYPE) as ChatModes;
};
/**
 * Utility to remove the chatType from localStorage.
 */
export const removeChatType = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.CHAT_TYPE);
};

/**
 * Utility to set the username in localStorage.
 * @param value - The username to be stored.
 */
export const persistUsername = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.USERNAME, value);
};

/**
 * Utility to get the username from localStorage.
 * @returns The username, or null if not found.
 */
export const getUsername = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.USERNAME);
};

/**
 * Utility to remove the username from localStorage.
 */
export const removeUsername = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USERNAME);
};

/**
 * Utility to set the token in localStorage.
 * @param value - The token to be stored.
 */
export const persistToken = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, value);
};

/**
 * Utility to get the token from localStorage.
 * @returns The token, or null if not found.
 */
export const getToken = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
};

/**
 * Utility to set the avatar in localStorage.
 * @param value - The avatar to be stored.
 */
export const persistAvatar = (value: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.AVATAR, value);
};

/**
 * Utility to get the avatar from localStorage.
 * @returns The avatar, or null if not found.
 */
export const getAvatar = (): string | null => {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.AVATAR);
};

/**
 * Utility to remove the token from localStorage.
 */
export const removeToken = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
};

/**
 * Utility to remove the avatar from localStorage.
 */
export const removeAvatar = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AVATAR);
};

/**
 * Utility to clear all specified keys from localStorage.
 */
export const clearAll = (): void => {
    removeChatType();
    removeUsername();
    removeToken();
    removeAvatar();
};