/* eslint-disable react/react-in-jsx-scope */
import {
  Button,
} from "@fluentui/react-components";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { MastercamMessage } from "../../../../models/MastercamMessage";
import { AdditionalData, Message } from "../../../../models/Message";
import "./ChatMessage.css";
import { isMastercamMode } from "../../../../utils/localStorageUtils";

export interface FtAlternative {
  score: string;
  label: string;
  name: string;
  short_description: string;
}

export interface FtFunctionAdditionalData extends AdditionalData {
  label: string;
  group: string;
  short_description: string;
  top_score: string;
  alternatives: string;
  error: string
}

export interface FtFunctionMessage extends Message {
  additionalData: undefined | FtFunctionAdditionalData;
}

const FTButton = ({ parsedMessage }: { parsedMessage: FtFunctionMessage }) => {
  const alternatives: FtAlternative[] = JSON.parse(
    parsedMessage.additionalData?.alternatives || "[]"
  );

  const alternativesButtons = alternatives.map((alternative) => {
    return (
      <div key={alternative.name}>
        <Button
          onClick={() => handleAlternativeButtonClick(alternative)}
          className="ft-button"
        >
          {alternative.label}
        </Button>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {alternative.short_description || ""}
        </ReactMarkdown>
      </div>
    );
  });

  const errorContent = () => {
    return (
      <div>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          No functions found. Please clarify what you were expecting.
        </ReactMarkdown>
      </div>
    )
  };

  const successContent = () => {
    return (
      <div>
        <Button
          onClick={() => handleButtonClick(parsedMessage)}
          className="ft-button"
        >
          {parsedMessage.additionalData?.label || parsedMessage.text}
        </Button>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {parsedMessage.additionalData?.short_description || ""}
        </ReactMarkdown>
        {alternativesButtons.length > 0 && (
          <div>
            <h4>Alternatives</h4>
            {alternativesButtons}
          </div>
        )}
      </div>
    )
  }

  const content = () => {
    if(parsedMessage.additionalData?.error == "NO_FT_FOUND")
    {
      return errorContent();
    }
    else
    {
      return successContent();
    }
  }

  return (
    <div>
      {content()}
    </div>
  );
};

const handleAlternativeButtonClick = (
  alterative: FtAlternative
) => {
  if (isMastercamMode() && window?.chrome?.webview) {
    const mastercamMessage = new MastercamMessage(alterative.name);
    window.chrome.webview.postMessage(mastercamMessage);
  }
};

const handleButtonClick = (parsedMessage: Message) => {
  if (isMastercamMode() && window?.chrome?.webview) {
    const mastercamMessage = new MastercamMessage(parsedMessage.text, parsedMessage.additionalData?.params);
    window.chrome.webview.postMessage(mastercamMessage);
  }
};

export default FTButton;
