import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  clearAll,
  getAvatar,
  getToken,
  getUsername,
  persistAvatar,
  persistToken,
  persistUsername,
} from "../utils/localStorageUtils";

import { userManager } from "./Authentication";
import { Navigate } from "react-router-dom";
const useSmfAuth = import.meta.env.VITE_USE_SMF_AUTH === "true";

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  username: string | null;
  avatarUrl: string | null;
  signIn: () => void;
  signOut: () => void;
  login: (username: string, token: string) => void;
  logout: () => void;
  signinCallBack: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [username, setUsername] = useState<string | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null> (null);

  function setContext(isAuth: boolean, token: string | null, username: string | null, avatarUrl: string | null)
  {
    setIsAuthenticated(isAuth);
    setToken(token);
    setUsername(username);
    setAvatarUrl(avatarUrl);
  }

  // Load authentication state from local storage on initial render
  useEffect(() => {
    const storedToken = getToken();
    const storedUsername = getUsername();
    const storedAvatar = getAvatar();

    if (storedToken && storedUsername) {
      setContext(true, storedToken, storedUsername, storedAvatar);
    }


    const renewToken = () => {
      userManager.signinSilent()
        .then(user => {
          setToken(user.access_token);
          setUsername(user.profile.email || "unknown");
        })
        .catch(error => {
          console.error("Token renewal error", error);
          signOut();
        });
    };

    userManager.events.addAccessTokenExpiring(() => {
      console.log("Access token is about to expire");
      renewToken();
    });

    userManager.events.addAccessTokenExpired(() => {
      console.log("Access token has expired");
      signOut();
    });

    return () => {
      userManager.events.removeAccessTokenExpiring(renewToken);
      userManager.events.removeAccessTokenExpired(signOut);
    };
  }, []);

  const signIn = () => {
    userManager.signinRedirect();
  }

  const signOut = () => {
    if (useSmfAuth) {
      logout();
      return;
    }

    setContext(false, null, null, null);
    clearAll();

    userManager.signoutRedirect();
  };

  const signinCallBack = () => {
    userManager.signinRedirectCallback()
      .then(user => {
        console.log("set auth");
        const userName = user.profile.given_name + " " + user.profile.family_name;
        persistUsername(userName);
        persistAvatar(user.profile.avatar);
        persistToken(user.access_token);
        setContext(true, user.access_token, userName, user.profile.avatar);
        window.location.href = "/chat";
      })
      .catch((error) => console.error("error", error));
  };

  const login = (username: string, token: string) => {
    persistUsername(username);
    persistToken(token);
    setContext(true, token, username, null);
  };

  const logout = () => {
    setContext(false, null, null, null);
    clearAll();
    <Navigate to="/" />;
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, token, username, avatarUrl, signIn, signOut, signinCallBack, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
