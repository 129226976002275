/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect, useState } from 'react'
import {
    Button,
    Persona,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Tooltip,
} from "@fluentui/react-components";

import './ChatToolBar.css';
import iconaNewTopic from "../../../../assets/New Topic.png";
import { useAuth } from "../../../../states/AuthContext";
import { ChatFeatureContextHandler } from '../../../../states/ChatContext'
import { History16Filled } from '@fluentui/react-icons';

export function ChatToolBar() {
    const contextHandler = useContext(ChatFeatureContextHandler)
    const { avatarUrl, isAuthenticated, signOut } = useAuth();
    const [version, setVersion] = useState('');

    useEffect(() => {
      fetch('/VERSION.txt')
        .then(response => response.text())
        .then(text => {
          setVersion(text.replace('VERSION', '').trim());
        })
        .catch(error => console.error('Error fetching the version file:', error));
    }, []);
  
    const handleLogout = () => {
        signOut();
    };

    return (

        <div className="toolbar">
            <Tooltip content="Show Chats..." relationship="label">
                <Button
                    style={{ minWidth: "0px" }}
                    appearance="transparent">
                    <History16Filled />
                </Button>
            </Tooltip>
            <Tooltip content="New Chat" relationship="label">
                <Button
                    onClick={contextHandler.onRestartConversation}
                    style={{ minWidth: "0px" }}
                    appearance="transparent">
                    <img src={iconaNewTopic} alt="New Topic" />
                </Button>
            </Tooltip>
            <div>
                {isAuthenticated && (
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance="transparent">
                                <Tooltip content="Account" relationship="label">
                                    <Persona style={{ minWidth: "0px" }} textPosition="after" avatar={{ image: { src: avatarUrl?.toString() } }} />
                                </Tooltip>
                            </MenuButton>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem onClick={contextHandler.onRestartConversation}>Restart conversation</MenuItem>
                                <MenuItem>History...</MenuItem>
                                <MenuItem>Settings...</MenuItem>
                                <MenuItem>Help...</MenuItem>
                                <MenuItem>Frequently Asked Questions...</MenuItem>
                                <MenuItem onClick={handleLogout}>Log out</MenuItem>
                                <MenuDivider />
                                <MenuItem disabled style={{ color: "black" }}>Version {version}</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                )}
            </div>
        </div>
    );
};
