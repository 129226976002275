
import React from "react";
import { useAuth } from "../../states/AuthContext";
import { useEffect } from "react";

const Signin: React.FC = () => {

  const {signinCallBack} = useAuth();

  useEffect(() => {
    signinCallBack();

  }, []);


  return (
    <></>
  )

};

export default Signin;
