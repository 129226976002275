/* eslint-disable react/react-in-jsx-scope */
import { useContext, useEffect, useRef } from "react";
import { EventType, Message, SenderType } from "../../../../models/Message";
import { ChatFeatureContext } from "../../../../states/ChatContext";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import "./ChatHistoryList.css";

export function ChatHistoryList() {
  const context = useContext(ChatFeatureContext);

  // Scrolling to the bottom of the list when a new message is added
  const messagesEnd = useRef<HTMLDivElement>(null);
  useEffect(
    () => messagesEnd?.current?.scrollIntoView({ behavior: "smooth" }),
    [context.conversation.messages]
  );

  function groupedMessages(messages: Message[]) {
    // just use the last DocChatPartial message for the same correlationId
    const docChatMessages = new Map<string, Message[]>();
    for (const message of messages) {
      const messagesForCorrelationId = docChatMessages.get(
        message.correlationId
      );
      if (messagesForCorrelationId) {
        messagesForCorrelationId?.push(message);
      } else {
        docChatMessages.set(message.correlationId, [message]);
      }
    }
    return docChatMessages;
  }

  function mergeMessages(messages: Message[] | undefined) {
    if (!messages) {
      return { messages: [], isLoading: false };
    }
    const docChatMap = new Map<string, Message>();
    for (const message of messages) {
      if (message.sender === SenderType.DocChat) {
        docChatMap.set(message.correlationId, message);
      }
    }
    const mergedMessages = messages
      .filter((message) => message.sender !== SenderType.DocChat)
      .filter((message) => message.event !== EventType.DebugLog);
    for (const docChatMessage of docChatMap.values()) {
      mergedMessages.push(docChatMessage);
    }

    const lastMessage = mergedMessages[mergedMessages.length - 1];
    const filteredMessages = mergedMessages.filter(
      (message) => message.event !== EventType.LoadingNotification
    );
    if (lastMessage && lastMessage.event === EventType.LoadingNotification) {
      filteredMessages.push(lastMessage);
    }

    return {
      messages: filteredMessages,
      isLoading: lastMessage?.event === EventType.LoadingNotification,
    };
  }

  return (
    <div className="messages-container">
      {Array.from(groupedMessages(context.conversation.messages).keys()).map(
        (key) => (
          <div key={key} className="doc-chat-group-container">
            {mergeMessages(
              groupedMessages(context.conversation.messages).get(key)
            ).messages.map((message) => (
              <div
                key={message.id}
                className={
                  message.sender === SenderType.User
                    ? "user-message-container"
                    : "copilot-message-container"
                }
              >
                <ChatMessage
                  key={message.id}
                  message={message}
                  isLoading={message.event === EventType.LoadingNotification}
                  loadingMessage={message.text}
                />
              </div>
            ))}
          </div>
        )
      )}
      <div ref={messagesEnd}></div>
    </div>
  );
}
