import {
  Button,
  Image,
  InputOnChangeData,
  Label,
  Textarea,
  Tooltip
} from "@fluentui/react-components";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ChatFeatureContextHandler } from "../../../../states/ChatContext";
import "./ChatInputBox.css";
import { ChatSettingsBar } from "../ChatSettingsBar/ChatSettingsBar";
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import iconMic from "../../../../assets/Voice Command.png";
import iconSend from "../../../../assets/Send.png";
import iconStop from "../../../../assets/Stop Button.png";
import { getToken } from "../../../../utils/localStorageUtils";
import ErrorPopup from "../../../common/ErrorPopup";

const backendUrl = import.meta.env.VITE_BACKEND_BASE_URL.endsWith('/')
  ? import.meta.env.VITE_BACKEND_BASE_URL
  : `${import.meta.env.VITE_BACKEND_BASE_URL}/`;

export interface TokenInfo {
  token: string,
  region: string
};

export function ChatInputBox() {
  const [isListening, setIsListening] = useState(false);
  const contextHandler = useContext(ChatFeatureContextHandler);
  const [message, setMessage] = useState("");
  const [showVoiceRecordingMessage, setShowVoiceRecordingMessage] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const recognizer = useRef<sdk.SpeechRecognizer | null>(null);
  const speechTokenizer = useRef<TokenInfo | null>(null);
  const aggregatedMessage = useRef<string>('');

  const showErrorPopup = (message: string) => {
    setErrorMessage(message);
    setIsErrorPopupOpen(true);
  };

  const handleDismiss = () => {
    setIsErrorPopupOpen(false);
  };

  const InitializeSpeechRecognition = () => {
    if (speechTokenizer.current) {
      const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
      const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(speechTokenizer.current.token, speechTokenizer.current.region);
      speechConfig.speechRecognitionLanguage = 'en-US';

      recognizer.current?.close();
      recognizer.current = new sdk.SpeechRecognizer(speechConfig, audioConfig);

      aggregatedMessage.current = "";
      recognizer.current.recognized = (_, e) => ProcessRecognizedTranscript(e);
      recognizer.current.recognizing = (_, e) => ProcessIntermediateTranscript(e);
    }
    else {
      throw new Error("No token available");
    }
  };

  const ProcessIntermediateTranscript = (event: sdk.SpeechRecognitionEventArgs) => {
    if (event.result.reason != sdk.ResultReason.NoMatch && event.result.languageDetectionConfidence === "Medium") {
      if (event.result.text.length > 0) {
        setMessage(event.result.text);
      }
    }
  };

  const ProcessRecognizedTranscript = (event: sdk.SpeechRecognitionEventArgs) => {
    if (event.result.reason === sdk.ResultReason.RecognizedSpeech) {
      if (event.result.text.length > 0) {
        console.log(event.result.text);
        aggregatedMessage.current += ' ' + event.result.text;
        aggregatedMessage.current = aggregatedMessage.current.replace(".", "");
        setMessage(aggregatedMessage.current);
      }
    }
  };

  const getSpeechToken = async () => {
    const accessToken = getToken();
    const uri = `${backendUrl}v1/speech/get-token-speech`
    console.log('Get Speech Service Token from: ', uri);
    const response = await fetch(uri, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      console.error("Speech Service Error: " + response);
      throw new Error("No Speech Service available");
    }

    const data = await response.json();
    return { token: data.token, region: data.region };
  };

  const startRecording = async () => {
    const promise = new Promise<void>((resolve, reject) => {
      recognizer.current?.startContinuousRecognitionAsync(
        () => {
          console.log("Recording started");
          setShowVoiceRecordingMessage(true);
          setIsListening(true);
          resolve();
        },
        (error) => {
          console.error(error);
          reject(error);
        }
      );
    });
    await promise;
  };

  const stopRecording = async () => {
    const promise = new Promise<void>((resolve) => {
      recognizer.current?.stopContinuousRecognitionAsync(() => {
        console.log("Recording stopped");
        setShowVoiceRecordingMessage(false);
        setIsListening(false);
        resolve();
      });
    });

    await promise;
  }

  const handleListen = async () => {
    if (isListening) {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      try {
        //const tokenInfo = await getSpeechToken();
        InitializeSpeechRecognition();
        await startRecording();
      }
      catch (e) {
        showErrorPopup((e as Error).message);
        await stopRecording();
      }
    }
    else {
      await stopRecording();
    }
  }

  useEffect(() => {
    const fetchToken = () => {
      getSpeechToken().then((tokenInfo) => {
        console.log("Fetch new Speech Service Token");
        speechTokenizer.current = tokenInfo;
      });
    };

    fetchToken(); // Initial fetchialFetch(false);
    const interval = setInterval(fetchToken, 5 * 60 * 1000); // Run every hour

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {

    handleListen();

  }, [isListening]);

  function onMessageChanged(
    _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    data?: InputOnChangeData
  ) {
    setIsListening(false);
    setMessage(data?.value || "");
  }

  function onEnterPress(event: React.KeyboardEvent<Element>) {
    if (event.key === "Enter" && !event.shiftKey) {
      onSendMessageClicked();
      event.preventDefault();
    }
  }

  function onSendMessageClicked() {
    setIsListening(false);
    if (message) {
      contextHandler.onSendMessage(message);
      setMessage("");
    }
  }

  return (
    <>

      <div className="input-message-container">
        <Textarea
          value={message}
          size="large"
          className="input-message"
          onChange={onMessageChanged}
          onKeyDown={onEnterPress}
        />
        <Tooltip content="Start Voice Chat" relationship="label">
          <Button
            className="button-mic"
            appearance="transparent"
            style={{ minWidth: "auto" }}
            onClick={() => setIsListening((prev) => prev = !prev)}
          >
            {!showVoiceRecordingMessage && <Image src={iconMic} alt="Start listening" />}
            {showVoiceRecordingMessage && <Image src={iconStop} alt="Stop listening" />}
          </Button>
        </Tooltip>
        <Tooltip content="Send and Dispatch (Enter)" relationship="label">
          <Button
            className="button-send"
            appearance="transparent"
            style={{ minWidth: "auto" }}
            onClick={onSendMessageClicked}
            disabled={!message}
          >
            <Image src={iconSend} alt="Send" />
          </Button>
        </Tooltip>
      </div>

      <div>
        <ChatSettingsBar />
        <Label className="message-disclaimer-ai">AI solutions should be verified for accuracy.</Label>
      </div>
      <ErrorPopup
        isOpen={isErrorPopupOpen}
        onDismiss={handleDismiss}
        errorMessage={errorMessage}
      />
    </>
  );
}
