import * as React from 'react';
import { Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, Button } from '@fluentui/react-components';

interface ErrorPopupProps {
    isOpen: boolean;
    onDismiss: () => void;
    errorMessage: string;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ isOpen, onDismiss, errorMessage }) => {
    return (
        <Dialog open={isOpen} onOpenChange={(_, { open }) => !open && onDismiss()}>
            <DialogSurface>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>{errorMessage}</DialogContent>
                <DialogActions>
                    <Button onClick={onDismiss}>Close</Button>
                </DialogActions>
            </DialogSurface>
        </Dialog>
    );
};

export default ErrorPopup;